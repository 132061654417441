import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { i18nConfig } from '../assets';
import { defaultArticle, defaultShopSettings } from './AppState';
import sessionService from '../services/SessionService';
import storageService from '../services/StorageService';
import shopSettingsService from '../services/ShopSettingsService';
import RenderUtil from '../util/RenderUtil';
import DefaultTheme from '../theme/DefaultTheme';
import {
  ArticleData,
  ArticleResource,
  MEASUREMENT_END,
  CONVERSION_ACTION,
  FUNNEL,
  Matching,
  MultiRecommendation,
  Recommendation,
  ShopSettings,
} from '../interfaces/ProductService.interface';
import { CtaPanelEvents } from '../components/CtaPanel';
import shopService from '../services/ShopService';
import checkoutService from '../services/CheckoutService';
import { Events } from '@footprint/common-components';
import { PollingEvents } from '../components/QRGateway/Interface';
import { RecommendationEvents } from '../components/Recommendation';
import { Modal, ModalView, ModalEvents } from '../components/Common/Modal';
import SplashscreenEvents from '../components/Splashscreen/Events';
import { PollingState } from '../components/QRGateway';
import { DefaultError } from '../error/DefaultError';
import clientEventsService from '../services/ClientEventsService';
import WidgetEvents from './Events';
import { AssetsService } from '../services/AssetsService';

export const ShopSettingsContext = React.createContext<ShopSettings>(defaultShopSettings);
export const ArticleContext = React.createContext<ArticleData>(defaultArticle);

export const AppController = (props: any) => {
  const { articleList, emitter, bannerId, headlessEnabled } = props;
  console.log('[driver] initializing appcontext');
  let currentArticle: ArticleData = null;
  let customerReference: string = null;

  const initLang = RenderUtil.getLang();
  const initMessages = RenderUtil.getTranslationMessages(initLang, i18nConfig);

  const [ shopSettings, setShopSettings] = useState(defaultShopSettings as ShopSettings);
  const [ article, setArticle ] = useState(defaultArticle as ArticleData);
  const [ locale, setLocale ] = useState(i18nConfig[initLang].locale);
  const [ messages, setMessages ] = useState(initMessages);
  const [ customTheme, setCustomTheme ] = useState(DefaultTheme);
  const [ language, setLanguage ] = useState(initLang);
  const [ modalView, setModalView ] = useState(ModalView.UNDEFINED);
  const [ modalData, setModalData ] = useState({} as any);

  let sessionId = storageService.getSessionId();

  if (sessionId) {
    shopService.updateSessionId(sessionId);
  }

  const clearModal = () => {
    RenderUtil.closeModal();
    setModalData({});
    setModalView(ModalView.UNDEFINED);
  };

  const handleTimeout = () => {
    sessionService.updateExitCode('PDP', 'qr.timeout');
    clientEventsService.createEvent('shop.qr.timeout');
  };

  const resetMeasurement = () => {
    storageService.clearMatchings();
    storageService.clearMeasurement();
  };

  const displaySplashscreen = () => {
    const funnel = shopSettings.funnel || FUNNEL.UNDEFINED;
    setModalData({ funnel });
    setModalView(ModalView.SPLASHSCREEN);
    RenderUtil.openModal();
  };

  const displayRecommendation = (matching: Matching) => {
    console.log('[driver] displaying recommendation');
    setModalData({ matching });
    setModalView(ModalView.RECOMMENDATION);
    RenderUtil.openModal();
  };

  const displayMultiRecommendation = (recommendation: MultiRecommendation) => {
    console.log('[driver] displaying multi recommendation');
    setModalData({ recommendation });
    setModalView(ModalView.MULTI_RECOMMENDATION);
    RenderUtil.openModal();
  };

  const displayError = (err: Error) => {
    if (!(err instanceof DefaultError)) {
      err = new DefaultError(
        'GEN01-00',
        'Something went wrong',
        { cause:err }
      );
    }
    setModalData({ error: err });
    setModalView(ModalView.ERROR);
    RenderUtil.openModal();
    shopService.logError(err as DefaultError, currentArticle);
  };

  const displayDesktopWidget = () => {

    sessionService.updateExitCode('PDP', 'qr.displayed');
    clientEventsService.createEvent('shop.qr.displayed')
      .then(() => {
        const target = process.env.FPT_APP_URL;
        const data = shopService.getQRData();
        const params = new URLSearchParams(data as any);
        params.append('article-number', currentArticle.number);
        const pollingEnabled = data.flow.split('-')[2] == MEASUREMENT_END.EXT;
        console.log('[driver] opening QRGateway', target);
        const url = new URL(`${target}?${params.toString()}`);
        setModalData({ target:url, polling: PollingState.UNDEFINED });
        setModalView(ModalView.QR);
        RenderUtil.openModal();

        if (pollingEnabled) {
          return shopService.waitForUserToStartMeasuring()
            .then(outcome => {
              setModalData({ target:url, polling: outcome });
              return shopService.waitForUserToFinishMeasuring();
            });
        }

        return PollingState.UNDEFINED;
      })
      .then((outcome: PollingState) => {

        if (outcome === PollingState.UNDEFINED) {
          // Nothing to do since polling was not enabled
          return;
        }

        console.log('[driver] finished measuring');

        shopService.handleMeasurementDone(currentArticle)
          .then((result:Matching) => {
            emitter.emit(WidgetEvents.onMatching, result);

            if (shopService.isSuccessfulMatching(result)) {
              displayRecommendation(result);
              shopService.createConversion(
                result,
                currentArticle,
                CONVERSION_ACTION.MATCHING_PRIMARY,
              );
              return;
            }

            throw new DefaultError(result.error_code, 'Matching failed');
          })
          .catch(err => {
            displayError(err);
          });
      })
      .catch(outcome => {

        if (outcome === PollingState.CANCELLED) {
          console.log('[driver] polling cancelled');
          clearModal();
          return;
        }

        if (outcome === PollingState.TIMEOUT) {
          console.log('[driver] polling timeout');
          handleTimeout();
          clearModal();
          return;
        }

        displayError(outcome);

      });

  };

  const displayStartScreen = () => {
    if (RenderUtil.isMobile()) {
      displaySplashscreen();
    } else {
      displayDesktopWidget();
    }
  };

  const handleFptStart = (data:any) => {
    console.log('[driver] fpt start called with payload', data);
    if (data && data.detail) {
      customerReference = data.detail.customer_reference;
    }
    shopService.createSession()
      .then(result => {
        shopService.updateSessionId(result);
        displayStartScreen();
      })
      .catch(error => {

        if (error.errorCode === 'WDAR1' && shopSettings.hasDefaultArticle) {
          return displayStartScreen();
        }

        displayError(error);
        let code = 'GEN01-00';

        if (error instanceof DefaultError) {
          code = error.errorCode;
        }

        sessionService.updateExitCode('error', code);
        console.error(error);
      });
  };

  const handleCtaButtonPress = (articleData: ArticleData) => {

    console.log(`[driver] CTA Button clicked ${articleData.number}`, articleData);
    setArticle(articleData);
    currentArticle = articleData;

    setModalView(ModalView.PROCESSING);
    RenderUtil.openModal();

    shopService.handleCtaButtonClick(articleData)
      .then((result:ArticleResource) => {
        if (currentArticle.gtin || currentArticle.ean) {
          console.log(`[driver] setting article number ${result.article_number}`);
          currentArticle.number = result.article_number;
          setArticle(currentArticle);
        }
        displayStartScreen();
      })
      .catch(error => {

        if (error.errorCode === 'WDAR1' && shopSettings.hasDefaultArticle) {
          return displayStartScreen();
        }

        displayError(error);
        let code = 'GEN01-00';

        if (error instanceof DefaultError) {
          code = error.errorCode;
        }

        sessionService.updateExitCode('error', code);
        console.error(error);
      });

  };

  const handleSingleMatch = (matching:Matching) => {
    emitter.emit(WidgetEvents.onMatching, matching);

    if (shopService.isSuccessfulMatching(matching)) {
      displayRecommendation(matching);
      shopService.createConversion(
        matching,
        currentArticle,
        CONVERSION_ACTION.MATCHING_PRIMARY,
      );
      return;
    }

    throw new DefaultError(matching.error_code, 'Matching failed');
  }

  const handleFunnelSelect = (funnel: FUNNEL) => {
    console.log(`[driver] ${funnel} funnel selected`);
    const option = funnel.toLowerCase();
    clientEventsService.createEvent(`shop.splashscreen.${option}-press`);
    clearModal();
    resetMeasurement();
    shopService.handleFunnelSelect(funnel, currentArticle)
      .then((data) => {
        const returnUrl = currentArticle?.returnUrl || shopSettings.returnUrl;
        if (returnUrl && data.result && data.result.measurementId) {
          window.location.href = shopService.parseReturnUrl(returnUrl,data.result,currentArticle);
          return;
        }
        return shopService.handleMeasuringResult(data, currentArticle);
      })
      .then((result:Matching|MultiRecommendation) => {

        if (!result) {
          return;
        }

        if (headlessEnabled && result.variants) {
          const recommendationEvent = new CustomEvent(
            'fpt-recommendation', { detail: result });
          window.dispatchEvent(recommendationEvent);
          return shopService.createRecommendationConversions(
            result as MultiRecommendation, customerReference);
        }

        if (result.variants) {
          return displayMultiRecommendation(result as MultiRecommendation);
        }

        handleSingleMatch(result as Matching);
      })
      .catch((err) => {
        if (err instanceof DefaultError && err.errorCode === 'WDCNV1') {
          console.warn('[driver] App closed by the user');
          return;
        }

        console.error('[driver] something went wrong', err);
        displayError(err);
      });
  };

  const handleLanguageSetting = (selectedLanguage: string) => {
    console.log(`[driver] Handling Language ${selectedLanguage} selected`);
    setLanguage(selectedLanguage);
    const { lang } = shopSettings;
    if (lang !== selectedLanguage) {
      shopSettings.lang = selectedLanguage;
      // setShopSettings(shopSettings);
    }
    AssetsService.fetchLanguageFile(selectedLanguage)
      .then( result => {
        console.log('[driver] setting language', selectedLanguage);
        i18nConfig[selectedLanguage].messages = result;
        setLocale(i18nConfig[selectedLanguage].locale);
        const m = RenderUtil.getTranslationMessages(selectedLanguage, i18nConfig);
        setMessages(m);
      });
  }

  const handleLanguageSelect = (selectedLanguage: string) => {
    handleLanguageSetting(selectedLanguage);
    clientEventsService.createEvent('shop.splashscreen.language-select', selectedLanguage);
  }

  const handleRecommendationPress = (data) => {
    console.log('[driver] Recommendation pressed', data);
    setArticle(data.article);
    currentArticle = data.article;
    const { matching } = data;

    if (matching.error_code === 'MAST0-00') {
      return displayRecommendation(matching);
    }
    sessionService.updateExitCode('error', matching.error_code);

    // let errorCode = matching.error_code;
    //
    // if (matching.error_code == 'BEDB1-00') {
    //   errorCode = 'BEDB1';
    // }

    displayError( new DefaultError(matching.error_code) );
  };

  const handleAddToCartPress = (recommendation: Recommendation) => {
    clientEventsService.createEvent(
      'shop.recommendation.add-to-cart-press', currentArticle.number);
    recommendation.session_id = storageService.getSessionId();
    const event = new CustomEvent('fpt-add-to-cart',
      { detail: recommendation as any });
    window.dispatchEvent(event);
    console.log('[driver] dispatched event', event);
    shopService.handleAddToCart(recommendation, currentArticle);
    clearModal();
  };

  const handleBackToShopPress = (matching: Matching) => {
    clearModal();
  };

  const handleRecommendationBackToShopPress = (recommendation: Recommendation) => {
    clientEventsService.createEvent('shop.recommendation.back-to-shop-press');
    clearModal();
    recommendation.session_id = storageService.getSessionId();
    const event = new CustomEvent('fpt-back-to-shop',
      { detail: recommendation as any });
    window.dispatchEvent(event);
    console.log('[driver] dispatched event', event);
  };

  const handleCancelPress = (recommendation: Recommendation) => {
    clientEventsService.createEvent('shop.recommendation.cancel-press');
    clearModal();
    recommendation.session_id = storageService.getSessionId();
    const event = new CustomEvent('fpt-cancel', { detail: recommendation as any });
    window.dispatchEvent(event);
    console.log('[driver] dispatched event', event);
  };

  const handleQRPanelClose = () => {
    clearModal();
    sessionService.updateExitCode('PDP', 'qr.closed');
    clientEventsService.createEvent('shop.qr.closed');
  };

  const handleMeasureAgainPress = () => {
    clientEventsService.createEvent('shop.recommendation.measure-again-press');
    console.log('[driver] New Measurement Press');
    resetMeasurement();

    shopService.createSession()
      .then(result => {
        shopService.updateSessionId(result);

        if (RenderUtil.isMobile()) {
          displaySplashscreen();
          return;
        }

        displayDesktopWidget();
      })

  };

  const handleCO2Press = () => {
    clientEventsService.createEvent('shop.recommendation.co2-press');
  };

  const processArticleData = (articleData: ArticleData, settings: ShopSettings) => {
    const { currency, lang, pricePattern } = settings;

    if (!articleData.currency) {
      articleData.currency = currency || 'EUR';
    }

    console.log(`[driver] using article price pattern ${pricePattern}`);
    console.log(`[driver] processing article price ${articleData.price}`);
    const priceNum = RenderUtil.parseArticlePrice(articleData.price, settings);

    const selectedLanguage = lang || language;

    articleData.sum = checkoutService.formatCurrency(
      priceNum, selectedLanguage, articleData.currency);

  };

  const displayFeedbackConfirmDialog = (recommendation: Recommendation) => {
    setModalData({ recommendation });
    setModalView(ModalView.FEEDBACK_CONFIRM);
    RenderUtil.openModal();
  };

  const handleFeedbackConfirm = (outcome) => {
    const confirmation = new CustomEvent('fpt-feedback-confirm', {detail:outcome});
    window.dispatchEvent(confirmation);
    clearModal();
    if (outcome.confirm) {
      shopService.logRecommendationFeedback(outcome.recommendation);
    }
  }

  const params = new URL(window.location.href).searchParams;

  useEffect(() => {
    console.log('[driver] Loading shop settings . . .');
    shopSettingsService.getSettings().then(settings => {
      console.log(`[driver] Got shop settings: ${settings}`);

      settings = settings || { domain: ''};

      const { lang, theme } = settings;

      if (lang && lang in i18nConfig) {
        handleLanguageSetting(lang);
      } else if (lang) {
        console.warn(`[driver] unsupported shop language setting: ${lang}`);
        AssetsService.fetchLanguageFile(language)
          .then( result => {
            console.log('[driver] setting language', language);
            i18nConfig[language].messages = result;
            setLocale(i18nConfig[language].locale);
            const m = RenderUtil.getTranslationMessages(language, i18nConfig);
            setMessages(m);
          });
      } else {
        console.warn(`[driver] setting browser language: ${language}`);
        AssetsService.fetchLanguageFile(language)
          .then( result => {
            console.log('[driver] setting language', language);
            i18nConfig[language].messages = result;
            setLocale(i18nConfig[language].locale);
            const m = RenderUtil.getTranslationMessages(language, i18nConfig);
            setMessages(m);
          });
      }

      if (theme) {
        const parsedTheme = RenderUtil.parseTheme(theme);
        setCustomTheme(parsedTheme);
      }

      setShopSettings(settings);
      shopService.setShopSettings(settings);
      emitter.emit(WidgetEvents.onSettingsLoaded, settings);

      if (
        params.has('fpt-measurement') &&
        params.has('fpt-session') &&
        params.has('fpt-article-number')
      ) {

        sessionId = params.get('fpt-session');
        shopService.updateSessionId(sessionId);
        const articleNumber = params.get('fpt-article-number');

        if (!articleList || articleList.length === 0) {
          console.warn('[driver] article list is empty!');
        }

        const articleData = articleList.find(
          item => item.number === articleNumber);

        if (articleData) {
          processArticleData(articleData, settings);
          setArticle(articleData);
          currentArticle = articleData;
        } else {
          const err = new Error(`unable to identify article ${articleNumber} on user redirect to shop`);
          displayError(err);
        }

      }
    });
  }, []);

  useEffect(() => {
    if (!shopSettings.domain) {
      return;
    }

    if (emitter.listeners(CtaPanelEvents.onCtaPress).length > 0) {
      return;
    }

    console.log(`[driver] adding event listeners with shop Settings: ${shopSettings.name}`);

    emitter.addListener(CtaPanelEvents.onCtaPress, handleCtaButtonPress);
    emitter.addListener(SplashscreenEvents.onFunnelSelect, handleFunnelSelect);
    emitter.addListener(SplashscreenEvents.onLanguageSelect, handleLanguageSelect);
    emitter.addListener(Events.onCancelPress, handleCancelPress);
    emitter.addListener(Events.onBackToShopPress, handleBackToShopPress);
    emitter.addListener(RecommendationEvents.onRecommendationBackToShopPress,
      handleRecommendationBackToShopPress);
    emitter.addListener(Events.onMeasureAgainPress, handleMeasureAgainPress);
    emitter.addListener(Events.onStartAgainPress, handleMeasureAgainPress);
    emitter.addListener(PollingEvents.onQRPanelClose, handleQRPanelClose);
    emitter.addListener(CtaPanelEvents.onRecommendationPress, handleRecommendationPress);
    emitter.addListener(RecommendationEvents.onCO2Press, handleCO2Press);
    emitter.addListener(RecommendationEvents.onAddToCartPress, handleAddToCartPress);
    emitter.addListener(ModalEvents.onCloseModalPress, clearModal);
    if (headlessEnabled) {
      console.log('[driver] Hooking to fpt start event');
      window.addEventListener('fpt-start', handleFptStart);
      emitter.addListener(RecommendationEvents.onFeedbackConfirm, handleFeedbackConfirm);
      window.addEventListener('fpt-feedback-submit', (data:CustomEvent) => displayFeedbackConfirmDialog(data.detail));
    }

    const initEvent = new Event('fpt-initialized');
    window.dispatchEvent(initEvent);

    if (
      params.has('fpt-measurement') &&
      params.has('fpt-session') &&
      params.has('fpt-article-number')
    ) {
      const measurementId = params.get('fpt-measurement');
      sessionId = params.get('fpt-session');

      if (article && article.id === 'test') {
        return;
      }

      shopService.handleMeasuringResult({
        result: {
          measurementId, sessionId,
        },
      }, article)
        .then((result:Matching) => {
          emitter.emit(WidgetEvents.onMatching, result);

          if (shopService.isSuccessfulMatching(result)) {
            displayRecommendation(result);
            shopService.createConversion(
              result,
              article,
              CONVERSION_ACTION.MATCHING_PRIMARY,
            );
            return;
          }

          throw new DefaultError(result.error_code, 'Matching failed');
        })
        .catch(err => {
          displayError(err);
        });
    }

    if (bannerId) {
      console.log('[driver] has banner id', bannerId);
      const banner = document.querySelector(`#${bannerId}`) as HTMLElement;
      const art = shopService.getArticleData(banner);
      banner.addEventListener('click', () => handleCtaButtonPress(art));
    }

  }, [article, shopSettings, language, customTheme]);


  return (
    <IntlProvider
        locale={locale}
        defaultLocale={i18nConfig.en.locale}
        messages={messages}>
        <ShopSettingsContext.Provider value={shopSettings}>
          <ArticleContext.Provider value={article}>
            <ThemeProvider theme={customTheme}>
              <Modal view={modalView} emitter={emitter} data={modalData} />
            </ThemeProvider>
          </ArticleContext.Provider>
        </ShopSettingsContext.Provider>
      </IntlProvider>
  );
};
